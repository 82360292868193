import { FormEvent, useCallback, useEffect, useId, useState } from 'react';
import { isFormValidated } from '../../../utils/formUtils';
import { Genders, TelePartners } from '../../../utils/mappings';
import { IPatientData } from '../../../interfaces/PatientInterface';
import Select from 'react-select';
import { endpoints } from '../../../utils/URLs';
import axios from 'axios';

const BasicInfo = ({
  patientData,
  handleBasicInfoChange,
  serviceCharge,
  handleChange,
  handleNext,
  selectChange,
  source,
}: {
  patientData: IPatientData;
  handleBasicInfoChange: Function;
  serviceCharge: number;
  handleChange: Function;
  handleNext: Function;
  selectChange?: Function;
  source: 'Telemedicine' | 'NonTelemedicine';
}) => {
  const formId = useId();
  const [pharmacyListSelect, setPharmacyListSelect] = useState([]);

  const proceedToNextPage = (event: FormEvent) => {
    //check if form is valid
    const formName = `basic-info-${formId}`;
    if (!isFormValidated(formName)) {
      return;
    }

    event.preventDefault();

    handleNext();
  };
  const pharmacySelectChange = (selectedValue: any) => {
    if (selectChange) {
      selectChange('pharmacyCode', selectedValue.value);
    }
  };
  const getPharmacyList = async () => {
    let url = `${endpoints.pharmacy.mainUrl}?pageSize=3000`;
    try {
      const response = await axios.get(url);
      const data = response.data.data;
      let selectData = data.map((val: any) => {
        return {
          label: val.pharmacyName,
          value: val.pharmacyCode,
        };
      });
      //set to state
      setPharmacyListSelect(selectData);
      //save to local
      //TODO: save to context
      sessionStorage.setItem('pharmacyList', JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }
  };

  const loadPharmacyList = useCallback(() => {
    const savedPharmacyList = sessionStorage.getItem('pharmacyList');
    if (savedPharmacyList !== null) {
      const pharmacyList = JSON.parse(savedPharmacyList);
      let selectData = pharmacyList.map(
        (val: {
          pharmacyName: string;
          pharmacyCode: string;
          address: string;
        }) => {
          return {
            label: `${val.pharmacyName} ${val.address}`,
            value: val.pharmacyCode,
          };
        },
      );
      //set to state
      setPharmacyListSelect(selectData);
    } else {
      //call api
      getPharmacyList();
    }
  }, []);

  //use effect to load all pharmacies if not already in session
  useEffect(() => {
    //load pharmacy list and save in session storage if not available. only when the handle to change it exists
    if (selectChange) {
      loadPharmacyList();
    }
  }, [loadPharmacyList, selectChange]);

  return (
    <section className='mt-5'>
      <form id={`basic-info-${formId}`}>
        <fieldset className='row'>
          <div className='form-group mt-3 col-6'>
            <label>
              Patient First name <span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              className='form-control'
              required
              value={patientData.firstName}
              onChange={handleBasicInfoChange('firstName')}
            />
          </div>
          <div className='form-group mt-3 col-6'>
            <label>
              Patient Last name <span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              className='form-control'
              required
              value={patientData.lastName}
              onChange={handleBasicInfoChange('lastName')}
            />
          </div>
          <div className='form-group mt-3 col-6'>
            <label>
              Gender <span className='text-danger'>*</span>
            </label>
            <select
              required
              className='form-select'
              value={patientData.gender}
              onChange={handleBasicInfoChange('gender')}
            >
              {Genders.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.text}
                </option>
              ))}
            </select>
          </div>
          <div className='form-group mt-3 col-6'>
            <label>Date of birth</label>
            <input
              type='date'
              className='form-control'
              value={patientData.dateOfBirth}
              onChange={handleBasicInfoChange('dateOfBirth')}
            />
          </div>
          <div className='form-group mt-3'>
            <label>
              Phone number <span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              className='form-control'
              value={patientData.phoneNumber}
              onChange={handleBasicInfoChange('phoneNumber')}
              maxLength={13}
              required
              pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
            />
          </div>
          <div className='form-group mt-3'>
            <label>
              Patient location <span className='text-danger'>*</span>
            </label>
            <textarea
              rows={1}
              className='form-control'
              required
              value={patientData.location}
              onChange={handleBasicInfoChange('location')}
            />
          </div>
          <div className='form-group mt-3'>
            <label>Service Rate</label>
            <input
              type='number'
              className='form-control'
              required
              value={serviceCharge}
              onChange={handleChange('serviceCharge')}
              min='1'
              max='10000'
            />
          </div>
          {/* Show pharmacy select if we have a handler for it */}
          {selectChange && (
            <div className='form-group mt-3'>
              <label>Select Pharmacy</label>
              <Select
                options={pharmacyListSelect}
                onChange={pharmacySelectChange}
                className='react-select'
                classNamePrefix='react-select'
              />
            </div>
          )}

          {source === 'Telemedicine' && (
            <div className='form-group mt-3'>
              <label>Telemedicine Partner</label>
              <select
                onChange={handleChange('partnerCode')}
                className='form-select'
              >
                {TelePartners.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className='mt-4 pt-5 d-grid'>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={proceedToNextPage}
            >
              Proceed
            </button>
          </div>
          <br />
        </fieldset>
      </form>
    </section>
  );
};

export default BasicInfo;
