import { IPrescriptionNote } from './NoteInterface';
import { IPatientData, initialPatient } from './PatientInterface';
import { IPractitionerData } from './PractitionerInterface';

export interface IPrescriptionResponse {
  consultationId: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  diagnosis: string;
  status: string;
  consultationType: string;
  consultationCode: string;
  serviceCharge: number;
  dateCreated: string;
}

export interface IPrescriptionDataDTO {
  patient: IPatientData;
  consultationType: 'Prescription' | 'MedicalAdvice' | 'InvestigationReport';
  status: 'Pending';
  diagnosis: string;
  serviceCharge: number;
  drugs: Array<IDrugs>;
  tests: Array<ITests>;
  // practitionerId: number;
  // sendToWefill: boolean;
  // pharmacyCode: string;
  // sendSms: boolean;
  // nextAppointment?: string;
  //consultationNote?: IPrescriptionNote;
}

export interface IPrescriptionData extends IPrescriptionDataDTO {
  // patient: IPatientData;
  // consultationType: 'Prescription' | 'MedicalAdvice' | 'InvestigationReport';
  // diagnosis: string;
  // serviceCharge: number;
  // drugs: Array<IDrugs>;
  // tests: Array<ITests>;
  practitionerId: number;
  sendToWefill: boolean;
  pharmacyCode: string;
  partnerCode: string;
  sendSms: boolean;
  nextAppointment?: string;
  consultationNote?: IPrescriptionNote;
}

export interface IPrescriptionDetailDTO extends IPrescriptionDataDTO {
  consultationNote: IPrescriptionNote[];
  practitioner: IPractitionerData;
}

export interface IDrugs {
  id: number; //local id
  drugId: number;
  name: string;
  dose: string;
  frequency: string;
  strength: string;
  duration: string;
  cost: number;
  notes: string;
}

export interface ITests {
  id: number; //local id
  testId: number;
  testName: string;
  cost: number;
  fileName: string;
  notes: string;
}
export interface IPrescriptionDetails {
  patientFirstName: string;
  drugs: Array<IDrugs>;
  tests: Array<ITests>;
  prescriptionCode: string;
}

export interface IDrugData {
  pricelistId: number;
  drugName: string;
  drugPrice: string;
  unitPrice: string;
  notes: string;
  dateCreated: string;
  dateUpdated: string;
}

export interface IPrescriptionUpdateData {
  prescriptionId: number;
  patientFirstName: string;
  patientLastName: string;
  patientLocation: string;
  patientEmail: string;
  diagnosis: string;
  status: string;
  serviceCharge: number;
}

export interface IPrescriptionSummaryAction {
  sendPrescription: Function;
  editPrescription: Function;
  handleChange: Function;
  options: IPrescriptionSummaryOptions;
}
export interface IPrescriptionSummaryOptions {
  sendViaWhatsapp: boolean;
  sendViaSms: boolean;
}

export const initialPrescriptionData: IPrescriptionData = {
  patient: initialPatient,
  practitionerId: 0,
  consultationType: 'Prescription',
  status: 'Pending',
  diagnosis: '',
  tests: [],
  drugs: [],
  serviceCharge: 1,
  sendToWefill: false,
  pharmacyCode: 'WHP10390',
  partnerCode: 'WHPX10135', //default to Axa
  sendSms: true,
};
export const initialDrugData: IDrugs = {
  id: 0,
  drugId: 0,
  name: '',
  dose: '',
  frequency: '',
  strength: '',
  duration: '',
  cost: 0,
  notes: '',
};

export const initialPrescriptionResponse: IPrescriptionResponse = {
  consultationId: 0,
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  diagnosis: '',
  serviceCharge: 0,
  consultationCode: '',
  consultationType: 'Prescription',
  status: 'Pending',
  dateCreated: new Date().toISOString().split('T')[0],
};
